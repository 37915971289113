import { Pipe, PipeTransform } from '@angular/core';

export type ICompareFn<T> = (a: T, b: T) => number;

@Pipe({
    name: 'libUiSorter',
})
export class SorterPipe implements PipeTransform {
    transform<T>(value: T[], compareFn: ICompareFn<T>): T[] {
        return [...(value ?? [])].sort(compareFn);
    }
}
