import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'lib-ui-coming-soon-snippet',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div
            class="from-ds-purple-50 to-ds-dark-50 flex h-full w-full flex-col items-center justify-center bg-gradient-to-r p-4 text-white"
        >
            <div class="max-w-lg text-center">
                <h1
                    class="from-ds-purple-500 to-ds-purple-400 bg-gradient-to-r bg-clip-text text-5xl font-extrabold leading-normal text-transparent"
                >
                    <ng-content>
                        {{ $title() }}
                    </ng-content>
                </h1>
            </div>
        </div>
    `,
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class ComingSoonSnippet {
    readonly $title = input('Coming soon...', { alias: 'title' });
}
